*{margin: 0; padding: 0;}

body {
  --fontLato: 'Lato', sans-serif;
  --brandColor: '#9c721c';
  --solidBlack: '#000'
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Lato', sans-serif;
}

*:focus {
  outline: none !important;
}

input {
  border: none;
}

.brand-color {
  color: #9c721c;
}

.brand-bg {
  background-color: #9c721c;
}

.black-text {
  color: var(--solidBlack);
}

.gray-text {
  color: #606060;
}

.off-white {
  color: rgba(255, 255, 255, 0.6);
}

button {
  border: none;
  background: none;
}

.custom-shadow {
  box-shadow: 0 0 10px rgb(231, 229, 229);
}

li{
  list-style-type: none;
}

.gradient-bg {
  background: linear-gradient(to right, #1e2329, #010101);
  color: #fff;
}

.link-style:hover {
  color: #fff;
  text-decoration: none;
  transition: all 0.5s ease;
}

.text-shadow {
  text-shadow: 2px 2px 5px rgb(51, 51, 51);
}

/* ============== (768px-991px) ================== */
@media (min-width: 768px) and (max-width: 991.98px) {
  
.header-title {
  font-size: 26px;
}
}


/* ============== (576px-767px) ================ */
@media (min-width: 576px) and (max-width: 767.98px) {
  .header-title {
    font-size: 26px;
  }
}
 
/* ============== (530px-575px) =============== */
@media (min-width: 530px) and (max-width: 575.98px) {
  .header-title {
    font-size: 26px;
  }
}
 
/* ============= (500px-529px) ================= */
@media (min-width: 500px) and (max-width: 529.98px) {
  .header-title {
    font-size: 26px;
  }
}
 
/* ============== (425px-499px) ================ */
@media (min-width: 425px) and (max-width: 499.98px) {
  .header-title {
    font-size: 22px;
  }
}
 
/* =============== (375px-424px) ================= */
@media (min-width: 375px) and (max-width: 424.98px) {
  .header-title {
    font-size: 22px;
  }
}
 
/* ============== (320px-374px) ================= */
@media (min-width: 320px) and (max-width: 374.98px) {
  .header-title {
    font-size: 22px;
  }
}
 
 
 
