.who-we-are-container {
    background: url(../../../images/who-we-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.read-more-btn {
    color: #fff;
    text-transform: uppercase;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 30px;
    margin-top: 10px;
}