.client-card {
    /* background: url(../../../images/client.png);
    background-repeat: no-repeat;
    height: 400px; */
}

.client-card-details {
    top: 20px;
    right: 15px;
}

.client-review {
    padding-left: 160px;
}

.client-social-icon {
    opacity: 0.6;
    transition: all 0.3s ease;
}

.client-social-icon:hover {
    opacity: 1;
}

.client-review-bottom {
    display: none;
}



@media (min-width: 992px) and (max-width: 1199px) {
    .client-review {
        display: none;
    }

    .client-review-bottom {
        display: block;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .client-card {
        width: 70%;
        margin: 0 auto;
    }
}

@media (min-width: 426px) and (max-width: 575.98px) {
    .client-card {
        width: 90%;
        margin: 0 auto;
    }
}