.about-us-header {
    background: url(https://i.ibb.co/mS4qkWJ/about.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 510px;
}

.circle-icon {
    font-size: 14px;
}

.circle-icon-small {
    font-size: 10px;
    margin-right: 15px;
}

.bullet-text {
    font-size: 17px;
    font-weight: bold;
}

/* =============== (1025px-1199.98px) ================ */
@media (min-width: 1200px) and (max-width: 1299.98px) {
} 
 
 
/* ============== (1025px-1199.98px) ================ */
@media (min-width: 1025px) and (max-width: 1199.98px) {
    .header-title {
        padding-right: 30px;
    }
    
}
 
/* ============== (992px-1024px) ================= */
@media (min-width: 992px) and (max-width: 1024.98px) {
    .about-us-header {
        height: 400px;
    }
}
 
 
/* ============== (768px-991px) ================== */
@media (min-width: 768px) and (max-width: 991.98px) {
    .about-us-header {
        height: 400px;
    }
    .bullet-text {
        font-size: 14px;
    }
    .about-us-title {
        font-size: 60px !important;
    }
}
 
 
/* ============== (576px-767px) ================ */
@media (min-width: 576px) and (max-width: 767.98px) {
    .about-us-header {
        height: 380px;
    }

    .about-us-title {
        font-size: 60px !important;
    }
}
 
/* ============== (530px-575px) =============== */
@media (min-width: 530px) and (max-width: 575.98px) {
    .about-us-header {
        height: 350px;
    }
    .about-us-title {
        font-size: 50px !important;
    }
}
 
/* ============= (500px-529px) ================= */
@media (min-width: 500px) and (max-width: 529.98px) {
    .about-us-header {
        height: 320px;
    }
    .about-us-title {
        font-size: 50px !important;
    }
}
 
/* ============== (425px-499px) ================ */
@media (min-width: 425px) and (max-width: 499.98px) {
    .about-us-header {
        height: 300px;
    }
    .about-us-title {
        font-size: 50px !important;
    }
}
 
/* =============== (375px-424px) ================= */
@media (min-width: 375px) and (max-width: 424.98px) {
    .about-us-header {
        height: 280px;
    }
    .about-us-title {
        font-size: 45px !important;
    }
}
 
/* ============== (320px-374px) ================= */
@media (min-width: 320px) and (max-width: 374.98px) {
    .about-us-header {
        height: 280px;
    }
    .about-us-title {
        font-size: 40px !important;
    }
}
 
 
 