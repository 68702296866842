.service-sub-title {
    font-size: 15px;
    color: #9c721c;
    margin-bottom: 5px;
}

.single-service-card {
    border-radius: 5px;
    margin-bottom: 10px;
 
}

.card-height {
    height: 200px;
}

.service-card-img {
    /* height: 70px; */
    width: 80px;
}

.see-more-btn {
    border: 1px solid black;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 5px 120px;
}

.see-more-btn:hover {
    background-color: black;
    color: #fff;
    transition: all 0.5s ease;
}

.line {
    display: block;
    width: 150px;
    height: 1.5px;
    background: gray;
    margin: 10px auto;
}



 
/* =============== (1025px-1199.98px) ================ */
@media (min-width: 1200px) and (max-width: 1299.98px) {
} 
 
 
/* ============== (1025px-1199.98px) ================ */
@media (min-width: 1025px) and (max-width: 1199.98px) {
    .card-height {
        height: 220px;
    }
}
 
/* ============== (992px-1024px) ================= */
@media (min-width: 992px) and (max-width: 1024.98px) {
  .service-title {
      font-size: 22px;
  }
}
 
 
/* ============== (768px-991px) ================== */
@media (min-width: 768px) and (max-width: 991.98px) {
    .service-title {
        font-size: 20px;
    }

    .card-columns {
        column-count: 2;
    }

}
 
 
/* ============== (576px-767px) ================ */
@media (min-width: 576px) and (max-width: 767.98px) {
    .service-title {
        font-size: 20px;
    }

    .card-columns {
        column-count: 1;
    }
}
 
/* ============== (530px-575px) =============== */
@media (min-width: 530px) and (max-width: 575.98px) {
    .service-title {
        font-size: 20px;
    }

    .card-columns {
        column-count: 1;
    }
}
 
/* ============= (500px-529px) ================= */
@media (min-width: 500px) and (max-width: 529.98px) {
    .service-title {
        font-size: 20px;
    }

    .card-columns {
        column-count: 1;
    }
}
 
/* ============== (425px-499px) ================ */
@media (min-width: 425px) and (max-width: 499.98px) {
    .service-title {
        font-size: 20px;
    }

    .card-columns {
        column-count: 1;
    }
}
 
/* =============== (375px-424px) ================= */
@media (min-width: 375px) and (max-width: 424.98px) {
    .service-title {
        font-size: 18px;
    }

    .card-columns {
        column-count: 1;
    }
    .see-more-btn {
        padding: 5px 80px;
    }
}
 
/* ============== (320px-374px) ================= */
@media (min-width: 320px) and (max-width: 374.98px) {
    .service-title {
        font-size: 18px;
    }

    .card-columns {
        column-count: 1;
    }
    .see-more-btn {
        padding: 5px 80px;
    }
}


 
 
 
