.contact-us-header {
    background: url(https://i.ibb.co/9pqqpvF/contactus.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 490px;
}

.contact-card-icon {
    height: 70px;
    margin-bottom: 10px;
}

.form-body {
    width: 80%;
}

.contact-form-label {
    font-size: 18px;
    color: #535252;
}

.contact-form-input {
    border: 1px solid #d1cece;
    border-radius: 5px;
    padding: 6px;
    width: 100%;
    /* margin-left: 30px; */
}

.extra-margin {
    margin-right: 45px;
}

.submit-enquiry-btn {
    margin-left: 140px;
}

.submit-enquiry-btn > input {
    padding: 6px 100px;
    border-radius: 5px;
    font-size: 18px;
    transition: all 0.5s ease;
}

/* for small device */
.small-submit-btn {
    padding: 5px 50px;
    border-radius: 5px;
}
/* =============== (1025px-1199.98px) ================ */
@media (min-width: 1200px) and (max-width: 1299.98px) {
} 
 
 
/* ============== (1025px-1199.98px) ================ */
@media (min-width: 1025px) and (max-width: 1199.98px) {
 
    
}
 
/* ============== (992px-1024px) ================= */
@media (min-width: 992px) and (max-width: 1024.98px) {
   
}
 
 
/* ============== (768px-991px) ================== */
@media (min-width: 768px) and (max-width: 991.98px) {
    .contact-us-header {
        height: 380px;
    }
    .form-body {
        width: 100%;
    }

    .contact-card-title {
        font-size: 15px;
    }
}
 

/* ============== (576px-767px) ================ */
@media (min-width: 576px) and (max-width: 767.98px) {
    .contact-us-header {
        height: 320px;
    }
    .form-body {
        width: 100%;
    }

    .submit-enquiry-btn {
        margin-left: 140px;
    }
    
    .submit-enquiry-btn > input {
        padding: 6px 50px;
    }

}
 
/* ============== (530px-575px) =============== */
@media (min-width: 530px) and (max-width: 575.98px) {
    .contact-us-header {
        height: 300px;
    }
}
 
/* ============= (500px-529px) ================= */
@media (min-width: 500px) and (max-width: 529.98px) {
    .contact-us-header {
        height: 300px;
    }
}
 
/* ============== (425px-499px) ================ */
@media (min-width: 425px) and (max-width: 499.98px) {
    .contact-us-header {
        height: 300px;
    }
}
 
/* =============== (375px-424px) ================= */
@media (min-width: 375px) and (max-width: 424.98px) {
    .contact-us-header {
        height: 280px;
    }
}
 
/* ============== (320px-374px) ================= */
@media (min-width: 320px) and (max-width: 374.98px) {
    .contact-us-header {
        height: 280px;
    }
}
 
 
 

