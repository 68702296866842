.newsletter-input {
    width: 75%;
}

.footer-social-icon {
    opacity: 0.6;
    transition: all 0.5s ease;
}

.footer-social-icon:hover {
    opacity: 1;
}

.horizontal-line {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}