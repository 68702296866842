.menu-link {
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 30px;
}

.menu-link:hover {
    text-decoration: none;
    color: #9c721c;
    
}


 
/* ============== (1025px-1199.98px) ================ */
@media (min-width: 1025px) and (max-width: 1199.98px) {
 
}
 
/* ============== (992px-1024px) ================= */
@media (min-width: 992px) and (max-width: 1024.98px) {
 
}
 
 
/* ============== (768px-991px) ================== */
@media (min-width: 768px) and (max-width: 991.98px) {
 
}
 
 
/* ============== (576px-767px) ================ */
@media (min-width: 576px) and (max-width: 767.98px) {
  .hamburger-menu {
      background-color: rgba(199, 144, 33, 0.6) !important;
  }
}
 
/* ============== (530px-575px) =============== */
@media (min-width: 530px) and (max-width: 575.98px) {
    .hamburger-menu {
        background-color: rgba(199, 144, 33, 0.6) !important;
    }
}
 
/* ============= (500px-529px) ================= */
@media (min-width: 500px) and (max-width: 529.98px) {
    .hamburger-menu {
        background-color: rgba(199, 144, 33, 0.6) !important;
    }
}
 
/* ============== (425px-499px) ================ */
@media (min-width: 425px) and (max-width: 499.98px) {

    .brand-logo {
        width: 80%;
    }

    .hamburger-menu {
        background-color: rgba(199, 144, 33, 0.6) !important;
    }
}
 
/* =============== (375px-424px) ================= */
@media (min-width: 375px) and (max-width: 424.98px) {
    
    .brand-logo {
        width: 80%;
    }
    
    .hamburger-menu {
        background-color: rgba(199, 144, 33, 0.6) !important;
    }
}
 
/* ============== (320px-374px) ================= */
@media (min-width: 320px) and (max-width: 374.98px) {
    
    .brand-logo {
        width: 80%;
    }
    
    .hamburger-menu {
        background-color: rgba(199, 144, 33, 0.6) !important;
        
    }
}
 
 
 

 
