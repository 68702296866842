.header-title {
    /* padding-right: 50px; */
}

.get-quote-btn {
    background-color: #000;
    color: #fff;
    padding: 7px 30px;
    border-radius: 5px;
    text-transform: uppercase;
}

.watch-video-btn {
    font-size: 15px;
    text-transform: uppercase;
}

.watch-video-icon {
    width: 40%;
}

 
/* =============== (1025px-1199.98px) ================ */
@media (min-width: 1200px) and (max-width: 1299.98px) {
} 
 
 
/* ============== (1025px-1199.98px) ================ */
@media (min-width: 1025px) and (max-width: 1199.98px) {
    .header-title {
        /* padding-right: 0px; */
    }
    
}
 
/* ============== (992px-1024px) ================= */
@media (min-width: 992px) and (max-width: 1024.98px) {
   
}
 
 
/* ============== (768px-991px) ================== */
@media (min-width: 768px) and (max-width: 991.98px) {
    .header-title {
        font-size: 26px;
        margin-top: 30px;
    }

    
.get-quote-btn {
    padding: 7px 20px;
    font-size: 14px;
}

.watch-video-btn {
    font-size: 14px;
    margin-left: -20px;
}

.watch-video-icon {
    width: 30%;
}
}
 
 
/* ============== (576px-767px) ================ */
@media (min-width: 576px) and (max-width: 767.98px) {
    .text-column {
        order: 2;
    }
    .image-column {
        order: 1;
    }

    .header-img {
        padding: 20px 50px;
    }
}
 
/* ============== (530px-575px) =============== */
@media (min-width: 530px) and (max-width: 575.98px) {
    .text-column {
        order: 2;
    }
    .image-column {
        order: 1;
    }

    .header-img {
        padding: 20px 50px;
    }
}
 
/* ============= (500px-529px) ================= */
@media (min-width: 500px) and (max-width: 529.98px) {
    .text-column {
        order: 2;
    }
    .image-column {
        order: 1;
    }

    .header-img {
        padding: 20px 50px;
    }
}
 
/* ============== (425px-499px) ================ */
@media (min-width: 425px) and (max-width: 499.98px) {
    .text-column {
        order: 2;
    }
    .image-column {
        order: 1;
    }

    .header-img {
        padding: 30px;
    }
    .watch-video-btn {
        margin-left: -20px;
    }
}
 
/* =============== (375px-424px) ================= */
@media (min-width: 375px) and (max-width: 424.98px) {
    .text-column {
        order: 2;
    }
    
    .image-column {
        order: 1;
    }

    .header-img {
        padding: 10px;
    }
    .get-quote-btn {
        padding: 4px 15px;
    }

    .watch-video-icon {
        margin-top: -2px;
    }
    .watch-video-btn {
        margin-left: -25px;
    }

}
 
/* ============== (320px-374px) ================= */
@media (min-width: 320px) and (max-width: 374.98px) {
    .header-title {
        font-size: 20px;
        margin-top: 30px;
    }
    .text-column {
        order: 2;
    }
    .image-column {
        order: 1;
    }
    .watch-video-btn {
        margin-left: -45px;
    }

}
 
 
 
